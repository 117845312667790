import React, { useState } from 'react';
import { IMedia } from '@types';
import CIcon from '@coreui/icons-react';
import { cilMagnifyingGlass } from '@coreui/icons';
import Svg from '@svg';

interface MediaProps {
  data?: IMedia;
  onClick?: (data: IMedia, e: React.MouseEvent<HTMLImageElement | HTMLVideoElement | SVGSVGElement>) => void;
}

export default function Media(props: MediaProps) {
  const [loaded, setLoaded] = useState(false);
  const media = props.data;

  return (
    <div className="position-relative">
      {!loaded && media?.previewBase64 && (
        <img
          src={`data:image/jpeg;charset=utf-8;base64, ${media.previewBase64}`}
          onClick={(e) => props.onClick?.call(null, media, e)}
          className="media bg-secondary"
          alt=""
        />
      )}
      {media?.type === 'Image' && (
        <img
          src={media.url}
          onClick={(e) => props.onClick?.call(null, media, e)}
          className="media bg-secondary"
          onLoad={() => setLoaded(true)}
          hidden={!loaded}
          alt=""
        />
      )}
      {media?.type === 'Video' && (
        <video
          src={media?.url}
          onClick={(e) => props.onClick?.call(null, media, e)}
          className="media bg-secondary"
          hidden={!loaded}
          onLoadedData={() => setLoaded(true)}
        />
      )}
      <CIcon
        icon={cilMagnifyingGlass}
        onClick={(e) => {
          if(media) {
            props.onClick?.call(null, media, e)
          }
        }}
        style={{
          position: 'absolute',
          left: '40%',
          top: '40%',
          width: '20%',
          height: '20%',
          color: 'white',
          cursor: 'pointer',
          filter: 'drop-shadow(0 0 1rem #000'
        }}
      />
    </div>
  );
}