import React, { useEffect, useMemo } from 'react';
import { FormMultiSelect } from '@component';
import { IOption, ObjectMap } from '@types';
import { useDispatch, useSelector } from 'react-redux';
import storage from '@storage';

interface SmartFormMultiSelectProps {
  name?: string;
  label?: string;
  description?: string | JSX.Element;
  placeholder?: string;
  hideDisabledOptions?: boolean;
  hidden?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string | string[];
  valueType?: 'string';
  emptyOption?: boolean;
  loading?: boolean;
  actionUrl: string;
  params?: ObjectMap;
  filterOptions?: (option: IOption) => boolean | undefined;
  virtualScroller?: boolean;
  visibleItems?: number;
  selectAll?: boolean;
  valueKey?: string;
  disabledIfEmpty?: boolean;
  onChange?: (options: IOption[]) => void;
  size?: 'sm' | 'lg';
  value?: string | string[] | number;
  className?: string;
}

export default function SmartFormMultiSelect(props: SmartFormMultiSelectProps) {
  const dispatch = useDispatch();
  const dropdownName = props.name ?? props.actionUrl;
  const loading = useSelector(storage.dropdown.selectLoading(dropdownName));
  const items = useSelector(storage.dropdown.selectItems(dropdownName));

  const options = useMemo(() => {
    return items
      .filter(option => option.value == props.value || !(option as any).hidden)
      .filter(option => option.value == props.value || !props.hideDisabledOptions || !option.disabled)
      .map(item => {
        const option = { ...item };
        if (props.value) {
          option.selected =
            typeof props.value === 'number' && props.value.toString() === item.value ||
            typeof props.value === 'string' && props.value === item.value ||
            typeof props.value === 'object' && props.value.includes(item.value ?? '');
        }
        return option;
      });
  }, [items, props.value, props.hidden, props.hideDisabledOptions]);

  useEffect(() => {
    if (props.loading !== false) {
      dispatch(storage.dropdown.setProps(
        dropdownName,
        props.actionUrl,
        props.params,
        props.loading,
        props.emptyOption));
    }
  }, [
    dropdownName,
    props.actionUrl,
    props.params,
    props.loading,
    props.emptyOption
  ]);

  useEffect(() => {
    if (loading) {
      dispatch(storage.dropdown.loadData(dropdownName));
    }
  }, [loading]);

  const valueKey = useMemo(() => props.valueKey ?? 'id', [props.valueKey]);

  const defaultValue = useMemo(() =>
      typeof props.defaultValue === 'string'
        ? [props.defaultValue]
        : props.defaultValue?.map(value => {
        return typeof value === 'object'
          ? (value[valueKey] as any)?.toString()
          : value.toString();
      }) ?? undefined,
    [props.defaultValue]);

  return (
    <FormMultiSelect
      name={props.name}
      label={props.label}
      description={props.description}
      placeholder={props.placeholder}
      required={props.required}
      readOnly={props.readOnly}
      disabled={props.disabled || (props.disabledIfEmpty && (!options || options?.length === 0))}
      hidden={props.hidden}
      defaultValue={defaultValue}
      options={options}
      virtualScroller={props.virtualScroller}
      filterOptions={props.filterOptions}
      visibleItems={props.visibleItems}
      selectAll={props.selectAll}
      onChange={(options) => props.onChange?.call(null, options)}
      size={props.size}
      className={props.className}
    />
  );
}