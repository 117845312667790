import React from 'react';
import { IRoute } from '@types';
import { useFormatTranslation } from '@hooks';
import { Navigate } from 'react-router-dom';

const Challenge = React.lazy(async () => import('@page/challenge/challenge'));
const Comment = React.lazy(async () => import('@page/comment/comment'));
const Employee = React.lazy(async () => import('@page/employee/employee'));
const Home = React.lazy(async () => import('@page/dashboard/dashboard'));
const Organization = React.lazy(async () => import('@page/organization/organization'));
const Profile = React.lazy(async () => import('@page/profile/profile'));
const Project = React.lazy(async () => import('@page/project/project'));
const RolePermission = React.lazy(async () => import('@page/role-permission/role-permission'));
const SystemFeature = React.lazy(async () => import('@page/system-feature/system-feature'));
const User = React.lazy(async () => import('@page/user/user'));
const Skill = React.lazy(async () => import('@page/skill/skill'));
const Interest = React.lazy(async () => import('@page/rubric/rubric'));
const Contest = React.lazy(async () => import('@page/contest/contest'));
const Prize = React.lazy(async () => import('@page/prize/prize'));
const News = React.lazy(async () => import('@page/news/news'));
const Festival = React.lazy(async () => import('@page/festival/festival'));
const GlossaryTerm = React.lazy(async () => import('@page/glossary-term/glossary-term'));
const Achievement = React.lazy(async () => import('@page/achievement/achievement'));
const Level = React.lazy(async () => import('@page/level/level'));
const ProjectComplaint = React.lazy(async () => import('@page/project-complaint/project-complaint'));
const Avatar = React.lazy(async () => import('@page/avatar/avatar'));
const UserComplaint = React.lazy(async () => import('@page/user-complaint/user-complaint'));
const ConsoleGlossaryTerm = React.lazy(async () => import('@page/console-glossary-term/console-glossary-term'));
const ContentFilter = React.lazy(async () => import('@page/content-filter/content-filter'));
const Product = React.lazy(async () => import('@page/product/product'));
const License = React.lazy(async () => import('@page/license/license'));
const Quiz = React.lazy(async () => import('@page/quizzes/quiz'));
const QuizModeration = React.lazy(async () => import('@page/quizzes-moderation/quiz-moderation'))
const Invite = React.lazy(async () => import('@page/invite/invite'));
const School = React.lazy(async () => import('@page/school/school'));
const College = React.lazy(async () => import('@page/college/college'));
const University = React.lazy(async () => import('@page/university/university'));

/**
 * Маршруты
 */
export function useRoutes(): IRoute[] {
  const { t } = useFormatTranslation();

  return [
    { path: '/', name: t('main'), element: <Home /> },
    { path: '/challenge/*', name: t('nav_type_content_item_challenge'), element: <Challenge /> },
    { path: '/comment/*', name: t('nav_type_moderation_item_comment'), element: <Comment /> },
    { path: '/employee/*', name: t('nav_type_user_item_employee'), element: <Employee /> },
    { path: '/organization/*', name: t('nav_type_system_item_organization'), element: <Organization /> },
    { path: '/profile', name: 'Профиль', element: <Profile /> },
    { path: '/project/*', name: t('nav_type_moderation_item_project'), element: <Project /> },
    { path: '/role/permission/*', name: t('nav_type_access_control_item_role_permission'), element: <Navigate to={"/role/permission/adm"} /> },
    { path: '/role/permission/adm/*', name: t('nav_type_access_control_item_role_permission_adm'), element: <RolePermission /> },
    { path: '/role/permission/app/*', name: t('nav_type_access_control_item_role_permission_app'), element: <RolePermission /> },
    { path: '/system-feature/*', name: t('nav_type_system_item_system_feature'), element: <SystemFeature /> },
    { path: '/user/*', name: t('nav_type_title_user'), element: <User /> },
    { path: '/skill/*', name: t('nav_type_content_item_skill'), element: <Skill /> },
    { path: '/rubric/*', name: t('nav_type_gamification_item_rubric'), element: <Interest /> },
    { path: '/prize/*', name: t('nav_type_event_item_event_prize'), element: <Prize /> },
    { path: '/contest/*', name: t('nav_type_event_item_event_contest'), element: <Contest /> },
    { path: '/news/*', name: t('nav_type_alert_item_news'), element: <News /> },
    { path: '/festival/*', name: t('nav_type_event_item_festival'), element: <Festival /> },
    { path: '/glossary-term/*', name: t('nav_type_system_item_glossary_term'), element: <GlossaryTerm /> },
    { path: '/console-glossary-term/*', name: t('nav_type_title_console_glossary_term'), element: <ConsoleGlossaryTerm /> },
    { path: '/content-filter/*', name: t('nav_type_system_item_content_filter'), element: <ContentFilter /> },
    { path: '/achievement/*', name: t('nav_type_gamification_item_achievement'), element: <Achievement /> },
    { path: '/level/*', name: t('nav_type_gamification_item_level'), element: <Level /> },
    { path: '/project-complaint/*', name: t('nav_type_moderation_item_project_complaint'), element: <ProjectComplaint /> },
    { path: '/avatar/*', name: t('nav_type_user_item_avatar'), element: <Avatar /> },
    { path: '/user-complaint/*', name: t('nav_type_moderation_item_user_complaint'), element: <UserComplaint /> },
    { path: '/product/*', name: 'Продукты', element: <Product /> },
    { path: '/license/*', name: 'Продукты', element: <License /> },
    { path: '/quiz/*', name: 'Тестирование', element: <Quiz /> },
    { path: '/quiz/moderation/*', name: 'Результаты тестирования', element: <QuizModeration /> },
    { path: '/invite/*', name: t('nav_type_title_invite'), element: <Invite /> },    
    { path: '/college/*', name: "Колледжи", element: <College /> }, 
    { path: '/school/*', name: "Школы", element: <School /> },
    { path: '/university/*', name: "Университеты", element: <University /> }
  ];
}