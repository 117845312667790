import { ReactComponent as icon_Android } from './icon/android.svg';
import { ReactComponent as icon_ArrowUpBottom } from './icon/arrow-up-bottom.svg';
import { ReactComponent as icon_CheckCircle } from './icon/check-circle.svg';
import { ReactComponent as icon_ChevronLeft } from './icon/chevron-left.svg';
import { ReactComponent as icon_ChevronRight } from './icon/chevron-right.svg';
import { ReactComponent as icon_Close } from './icon/close.svg';
import { ReactComponent as icon_DeleteButton } from './icon/delete-button.svg';
import { ReactComponent as icon_EditPencil } from './icon/edit-pencil.svg';
import { ReactComponent as icon_Heart } from './icon/heart.svg';
import { ReactComponent as icon_ImageNotFound } from './icon/image-not-found.svg';
import { ReactComponent as icon_Kebab } from './icon/kebab.svg';
import { ReactComponent as icon_LineWrapping } from './icon/line-wrapping.svg';
import { ReactComponent as icon_Pause } from './icon/pause.svg';
import { ReactComponent as icon_Play } from './icon/play.svg';
import { ReactComponent as icon_Question } from './icon/question.svg';
import { ReactComponent as icon_User } from './icon/user.svg';
import { ReactComponent as icon_ViewEye } from './icon/view-eye.svg';
import { ReactComponent as logo_GooglePlay } from './logo/google-play.svg';
import { ReactComponent as logo_Round } from './logo/round.svg';
import { ReactComponent as icon_Save } from './icon/save.svg';
import { ReactComponent as icon_Snail } from './icon/snail.svg';
import { ReactComponent as icon_Timer } from './icon/timer.svg';
import { ReactComponent as icon_DifficultyEasy } from './icon/difficulty-easy.svg';
import { ReactComponent as icon_DifficultyNorm } from './icon/difficulty-norm.svg';
import { ReactComponent as icon_DifficultyHard } from './icon/difficulty-hard.svg';

/**
 * Иконка
 */
const Icon = {
  Android: icon_Android,
  ArrowUpBottom: icon_ArrowUpBottom,
  CheckCircle: icon_CheckCircle,
  ChevronLeft: icon_ChevronLeft,
  ChevronRight: icon_ChevronRight,
  Close: icon_Close,
  DeleteButton: icon_DeleteButton,
  EditPencil: icon_EditPencil,
  Heart: icon_Heart,
  ImageNotFound: icon_ImageNotFound,
  Kebab: icon_Kebab,
  LineWrapping: icon_LineWrapping,
  Pause: icon_Pause,
  Play: icon_Play,
  Question: icon_Question,
  User: icon_User,
  ViewEye: icon_ViewEye,
  Save: icon_Save,
  Snail: icon_Snail,
  Timer: icon_Timer,
  DifficultyEasy: icon_DifficultyEasy,
  DifficultyNorm: icon_DifficultyNorm,
  DifficultyHard: icon_DifficultyHard
};

/**
 * Логотип
 */
const Logo = {
  GooglePlay: logo_GooglePlay,
  Round: logo_Round
};

/**
 * SVG-картинки
 */
const Svg = {

  /**
   * Иконка
   */
  Icon,

  /**
   * Логотип
   */
  Logo
};

/**
 * SVG-картинки
 */
export default Svg;
