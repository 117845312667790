import React from 'react';
import { IImage } from '@types';
import { CTooltip } from '@coreui/react-pro';

const _style: React.CSSProperties = {
  width: 128,
  borderRadius: 6,
  margin: '4px 0'
};

interface ImageTooltipProps {
  children?: React.ReactNode;
  image?: IImage;
}

export default function ImageTooltip(props: ImageTooltipProps) {
  return props.image?.url
    ? (
      <CTooltip
        content={
          <img
            src={props.image.url}
            alt=''
            style={_style} />
        }>
        {props.children}
      </CTooltip>
    ) : (
      <>{props.children}</>
    );
}